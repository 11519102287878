
import { defineComponent, reactive, inject, ref } from "vue";
import { IAuth, AdminLoginModel } from "@/apis/interface/IAuth";
import { RespBodyAuth } from "@/apis/interface/Resp";
import { useRouter } from "vue-router";
import store from "@/store";
import authHandler from "@/utils/auth-handler";

export default defineComponent({
  setup() {
    const apiAuth = inject("apiAuth") as IAuth;
    const router = useRouter();

    const loginData = reactive({
      keystring: "",
      password: ""
    });

    const isLogining = ref(false);

    const poster = require("@/assets/poster.png");
    const siteTitle = require("@/assets/site-title.png");

    const onSubmit = async () => {
      isLogining.value = true;

      let model: AdminLoginModel = {
        password: loginData.password,
        phone: loginData.keystring,
        username: loginData.keystring
      };

      const data = (await apiAuth.login(model)) as RespBodyAuth;
      authHandler.setAuth(data);

      //登录设置路由菜单
      store.dispatch("authData/INITIALIZE").then(() => {
        isLogining.value = false;
        if (data.object.meta.menus.length > 0) {
          router.push("/home");
        } else {
          router.push("/admin-info");
        }
      });
    };

    return {
      logo: require("@/assets/logo-horizontal.png"),
      isLogining,
      loginData: loginData,
      poster,
      siteTitle,
      onSubmit: onSubmit
    };
  }
});
